import * as React from 'react';
import {
    Toggle, DatePicker, defaultDatePickerStrings, DayOfWeek, TextField, Label,
    Dropdown, IDropdownOption, PrimaryButton, DefaultButton, TooltipHost, Text
} from '@fluentui/react';
import { IRelease, IReleaseNote } from './ReleaseNotes';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'


const buttonStyles = { root: { marginRight: 8 } };

interface IReleaseNoteEditorState {
    selectedIndex: number;
    releases: IRelease[];
    releaseNotes: IReleaseNote[];
    editedNote?: IReleaseNote;
    id: string;
    title: string;
    version: string;
    tags: string[];
    state: string;
    majorChange: boolean;
    actionRequiredBy: Date;
    description: string;
    loaded: boolean;
}

const maxDecriptionSize: number = 12 * 1024;

export class ReleaseNoteEditor extends React.Component<{ selectedIndex: number, releaseNotes: IReleaseNote[], releases: IRelease[], onSave?: (data: IReleaseNote) => void, onCancel?: () => void, onValidate?: (valid: boolean) => void }, IReleaseNoteEditorState> {
    private quillRef: ReactQuill | null = null;

    constructor(props: { selectedIndex: number, releaseNotes: IReleaseNote[], releases: IRelease[], onSave?: (data: IReleaseNote) => void, onCancel?: () => void, onValidate?: (valid: boolean) => void }) {
        super(props);

        this.state = {
            selectedIndex: props.selectedIndex,
            releases: props.releases,
            releaseNotes: props.releaseNotes,
            editedNote: undefined,
            id: "",
            title: "",
            version: "",
            tags: [],
            state: "Planned",
            majorChange: false,
            actionRequiredBy: new Date(),
            description: "",
            loaded: false
        };
    }

    private imageHandler = (image: any, callback: any) => {
        if (!this.quillRef || this.quillRef == null)
            return;

        var range = this.quillRef.getEditor().getSelection();
        var value = prompt('What is the image URL');
        if (value) {
            this.quillRef.getEditor().insertEmbed(range ? range.index : 0, 'image', value, "user");
        }
    }

    public render() {
        const {
            selectedIndex, releases, releaseNotes, editedNote,
            id, title, version, tags, actionRequiredBy, majorChange, state, description, loaded
        } = this.state;

        if (!loaded)
            return (<div />);

        const tagsOptions = [
            { key: "Integration", text: "Integration" },
            { key: "Notification", text: "Notification" },
            { key: "Portal", text: "Portal" },
            { key: "Sync", text: "Sync" },
            { key: "Teams App", text: "Teams App" }
        ];

        const stateOptions = [
            { key: "Planned", text: "Planned" },
            { key: "In development", text: "In development" },
            { key: "Released", text: "Released" }
        ];

        var toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }],
            ['link', 'image'],

            ['clean']                                         // remove formatting button
        ];

        const validateIdValue = (value: string): string => {
            if (value.length <= 0)
                return 'Required value';

            if (value.trim() !== editedNote?.releaseNoteId.trim() && releaseNotes.find((n) => n.releaseNoteId.trim() === value.trim()))
                return 'Id is already in use.';

            return ''
        };

        const getErrorMessage = (value: string): string => {
            return value.length > 0 ? '' : 'Required value.';
        };

        return (
            <div className="container">
                <div className="row">
                    <div className="col-2">
                        <TextField label="Id" value={id} required onGetErrorMessage={validateIdValue} validateOnFocusOut
                            disabled={selectedIndex >= 0}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.setStateValue('id', newValue)} />
                    </div>
                    <div className="col">
                        <TextField label="Title" value={title} required onGetErrorMessage={getErrorMessage} validateOnFocusOut
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.setStateValue('title', newValue)} 
                        />
                    </div>
                </div>

                <div className="row">            
                    <div className="col">
                        <Dropdown label="Version" options={
                            releases.map((r) => {
                                return {
                                    key: r.version,
                                    text: r.version
                                }
                            })}
                            selectedKey={version}
                            required
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => this.setStateValue('version', option?.text)}
                        />
                    </div>

                    <div className="col">
                        <Dropdown label="Tags" options={tagsOptions} selectedKeys={tags} multiSelect required
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                var idx: number = option ? tags.indexOf(option.text.trim()) : -1;
                                var t: string[] = tags;

                                if (option)
                                    if (option?.selected && idx === -1)
                                        t.push(option.text.trim());
                                    else if (idx > -1) {
                                        var ft: string[] | undefined = tags.filter(t => t !== option.text.trim());
                                        t = ft ? ft : [];
                                    }

                                this.setStateValue('tags', t);
                            }}
                        />
                    </div>
                    
                    <div className="col">
                        <DatePicker
                            firstDayOfWeek={DayOfWeek.Sunday}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            // DatePicker uses English strings by default. For localized apps, you must override this prop.
                            strings={defaultDatePickerStrings}
                            label="Action required by"
                            value={new Date(actionRequiredBy)}
                            isRequired
                            onSelectDate={(date: Date | null | undefined) => this.setStateValue('actionRequiredBy', date)}
                        />
                    </div>
                </div>
                <div className="row"> 
                    <div className="col">
                        <Dropdown label="State" options={stateOptions}
                            selectedKey={state}
                            required
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => this.setStateValue('state', option?.text)}
                        />
                    </div>
                    <div className="col">
                        <Toggle label="Major change" onText="Yes" offText="No" defaultChecked={majorChange}
                            onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this.setStateValue('majorChange', checked)}
                        />
                    </div>
                </div>
                <div className="row">
                    
                    <div className="col">
                        <TooltipHost>
                            <Label required>Description</Label>
                            <ReactQuill                               
                                ref={(el) => { this.quillRef = el }}
                                theme='snow'
                                value={description}
                                modules={{
                                    toolbar: {
                                        container: toolbarOptions,
                                        handlers: {
                                            image: this.imageHandler
                                        }
                                    }
                                }}
                                onChange={(content, delta, source, editor) => this.setStateValue('description', content)}
                            />
                            <Text variant='small' styles={{ root: { color: getTextSize(description) > maxDecriptionSize ? 'red' : '' } }}>Size: {getTextSize(description).toLocaleString()} / {maxDecriptionSize.toLocaleString()} bytes</Text>
                        </TooltipHost>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <br />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <PrimaryButton onClick={() => this.saveReleaseNote()} styles={buttonStyles} disabled={this.props.onSave === undefined || !this.formValid()} >
                            Save
                        </PrimaryButton>
                        <DefaultButton onClick={() => this.props.onCancel ? this.props.onCancel() : ""}>Cancel</DefaultButton>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const editedNote: IReleaseNote | undefined = this.props.selectedIndex >= 0 ? this.props.releaseNotes.find((r) => r.id === this.props.selectedIndex) : undefined;

        const editedNoteCopy = editedNote ? JSON.parse(JSON.stringify(editedNote)) : undefined;
        this.setState({
            selectedIndex: this.props.selectedIndex,
            releases: this.props.releases,
            releaseNotes: this.props.releaseNotes,
            editedNote: editedNoteCopy,
            id: editedNoteCopy ? editedNoteCopy.releaseNoteId : "",
            title: editedNoteCopy ? editedNoteCopy.title : "",
            version: editedNoteCopy ? editedNoteCopy.version : "",
            state: editedNoteCopy ? editedNoteCopy.state : "Planned",
            tags: editedNoteCopy ? editedNoteCopy.tags : [],
            majorChange: editedNoteCopy ? editedNoteCopy.majorChange : false,
            actionRequiredBy: editedNoteCopy ? new Date(editedNoteCopy.actionRequiredBy) : new Date(),
            description: editedNoteCopy ? editedNoteCopy.description : "",
            loaded: true
        });
    }

    setStateValue(key: string, value: any): void {
        var obj: any = {};
        obj[key] = value;

        this.setState(obj);
    }

    saveReleaseNote(): void {
        if (this.props.onSave) {
            var releaseNote: IReleaseNote = {
                id: this.state.selectedIndex,
                key: this.state.selectedIndex,
                releaseNoteId: this.state.id,
                title: this.state.title,
                version: this.state.version,
                tags: this.state.tags,
                state: this.state.state,
                majorChange: this.state.majorChange,
                description: this.state.description,
                actionRequiredBy: this.state.actionRequiredBy,
                lastModified: new Date(),
                lastModifiedBy: ""
            }

            this.props.onSave(releaseNote);
        }
    }

    formValid(): boolean {
        return this.state.id.trim() !== "" &&
            !(this.state.id.trim() !== this.state.editedNote?.releaseNoteId.trim() && this.state.releaseNotes.find((n) => n.releaseNoteId.trim() === this.state.id.trim())) &&
            this.state.description.trim() !== "" &&
            getTextSize(this.state.description) <= maxDecriptionSize &&
            this.state.title.trim() !== "" &&
            this.state.version.trim() !== "" ? true : false
    }
}

function getTextSize(text: string): number {
    return (new TextEncoder().encode(text)).length;
}