import * as React from 'react';
import {
    DatePicker, defaultDatePickerStrings, DayOfWeek, TextField, PrimaryButton, DefaultButton
} from '@fluentui/react';
import { IRelease } from './ReleaseNotes';
import { AlertMessage } from './AlertMessage';

const buttonStyles = { root: { marginRight: 8 } };

interface IReleaseEditorState {
    selectedIndex: number;
    releases: IRelease[];
    editedRelease?: IRelease;
    title: string;
    version: string;
    releaseDate: Date;
    alertOpen: boolean;
}

export class ReleaseEditor extends React.Component<{ selectedIndex: number, releases: IRelease[], onSave?: (data: IRelease) => void, onDelete?: (data: IRelease) => void, onCancel?: () => void, onValidate?: (valid: boolean) => void }, IReleaseEditorState> {

    constructor(props: { selectedIndex: number, releases: IRelease[], onSave?: (data: IRelease) => void, onDelete?: (data: IRelease) => void, onCancel?: () => void, onValidate?: (valid: boolean) => void }) {
        super(props);

        this.state = {
            selectedIndex: props.selectedIndex,
            releases: props.releases,
            editedRelease: undefined,
            title: "",
            version: "",
            releaseDate: new Date(),
            alertOpen: false
        };
    }

    public render() {
        const {
            selectedIndex, releases, editedRelease, title, version, releaseDate, alertOpen
        } = this.state;

        const validateVersionValue = (value: string): string => {
            if (value.length <= 0)
                return 'Required value';

            if (value.trim() !== editedRelease?.version.trim() && releases.find((n) => n.version.trim() === value.trim()))
                return 'Id is already in use.';

            return ''
        };

        const getErrorMessage = (value: string): string => {
            return value.length > 0 ? '' : 'Required value.';
        };

        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <TextField label="Version" value={version} required onGetErrorMessage={validateVersionValue} validateOnFocusOut
                            disabled={selectedIndex >= 0}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.setStateValue('version', newValue)} />
                    </div>
                    <div className="col">
                        <TextField label="Title" value={title} required onGetErrorMessage={getErrorMessage} validateOnFocusOut
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.setStateValue('title', newValue)} 
                        />
                    </div>
                </div>

                <div className="row">            
                    <div className="col">
                        <DatePicker
                            firstDayOfWeek={DayOfWeek.Sunday}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            // DatePicker uses English strings by default. For localized apps, you must override this prop.
                            strings={defaultDatePickerStrings}
                            label="Release date"
                            value={new Date(releaseDate.toUTCString())}
                            isRequired
                            onSelectDate={(date: Date | null | undefined) => this.setStateValue('releaseDate', date ? new Date(date?.toUTCString()) : new Date())}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <br />
                    </div>
                </div>

                <AlertMessage
                    isBlocking={true}
                    title='Remove release'
                    header='Are you sure you want to remove this release?'
                    message='Removing release will also remove all attached release notes. Please proceed with caution.'
                    isOpen={alertOpen}
                    actions={
                        [
                            {
                                title: 'Yes',
                                action: () => this.deleteReleaseNote()
                            }
                        ]
                    }
                    closeActionTitle='No'
                    onClose={() => this.closeAlert()}
                />

                <div className="row">
                    <div className="col">
                        <PrimaryButton onClick={() => this.saveReleaseNote()} styles={buttonStyles} disabled={this.props.onSave === undefined || !this.formValid()} >
                            Save
                        </PrimaryButton>
                        {!(this.props.onDelete === undefined || selectedIndex < 0) ?
                            (<DefaultButton onClick={() => this.deleteReleaseNoteVerify()} styles={buttonStyles} >
                                Delete
                            </DefaultButton>) : ""}
                        <DefaultButton onClick={() => this.props.onCancel ? this.props.onCancel() : ""}>Cancel</DefaultButton>
                    </div>
                </div>

            </div>
        );
    }

    componentDidMount() {
        const editedRelease: IRelease | undefined = this.props.selectedIndex >= 0 ? this.props.releases.find((r) => r.id === this.props.selectedIndex) : undefined;

        const editedReleaseCopy = editedRelease ? JSON.parse(JSON.stringify(editedRelease)) : undefined;
        this.setState({
            selectedIndex: this.props.selectedIndex,
            releases: this.props.releases,
            editedRelease: editedReleaseCopy,
            title: editedReleaseCopy ? editedReleaseCopy.title : "",
            version: editedReleaseCopy ? editedReleaseCopy.version : "",
            releaseDate: editedReleaseCopy ? new Date(editedReleaseCopy.releaseTimestamp) : new Date()
        });
    }

    setStateValue(key: string, value: any): void {
        var obj: any = {};
        obj[key] = value;

        this.setState(obj);
    }

    saveReleaseNote(): void {
        const editedRelease: IRelease | undefined = this.props.selectedIndex >= 0 ? this.props.releases.find((r) => r.id === this.props.selectedIndex) : undefined;

        if (this.props.onSave) {
            var releaseNote: IRelease = {
                id: this.state.selectedIndex,
                key: this.state.selectedIndex,
                title: this.state.title,
                version: this.state.version,
                notes: this.props.selectedIndex > -1 ? (editedRelease ? editedRelease.notes : []) : [],
                releaseTimestamp: this.state.releaseDate,
                lastModified: new Date(),
                lastModifiedBy: ""
            }

            this.props.onSave(releaseNote);
        }
    }

    deleteReleaseNoteVerify(): void {
        this.setState({
            alertOpen: true
        });
    }

    closeAlert(): void {
        this.setState({
            alertOpen: false
        });
    }

    deleteReleaseNote(): void {
        const editedRelease: IRelease | undefined = this.props.selectedIndex >= 0 ? this.props.releases.find((r) => r.id === this.props.selectedIndex) : undefined;

        this.closeAlert();

        if (this.props.onDelete) {
            var releaseNote: IRelease = {
                id: this.state.selectedIndex,
                key: this.state.selectedIndex,
                title: this.state.title,
                version: this.state.version,
                notes: this.props.selectedIndex > -1 ? (editedRelease ? editedRelease.notes : []) : [],
                releaseTimestamp: this.state.releaseDate,
                lastModified: new Date(),
                lastModifiedBy: ""
            }

            this.props.onDelete(releaseNote);
        }
    }

    formValid(): boolean {
        return this.state.version.trim() !== "" &&
            !(this.state.version.trim() !== this.state.editedRelease?.version.trim() && this.state.releases.find((n) => n.version.trim() === this.state.version.trim())) &&
            this.state.title.trim() !== "" ? true : false
    }
}