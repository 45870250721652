import * as React from 'react';
import { Text, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';

export interface IAlertState {
    title: string;
    header?: string;
    message?: string;
    technicalDetails?: string;
    isOpen: boolean;
    isBlocking: boolean;
    actions?: IAlertAction[];
    closeActionTitle: string;
}

interface IAlertAction {
    title: string;
    primary?: boolean;
    action: () => void;
}

const buttonStyles = { root: { marginRight: 8 } };

export class AlertMessage extends React.Component<{
    title: string, header?: string, message?: string, technicalDetails?: string,
    actions?: IAlertAction[], closeActionTitle?: string, onClose?: () => void,
    isOpen: boolean, isBlocking: boolean, children?: JSX.Element | JSX.Element[]
}, IAlertState> {
    constructor(props: {
        title: string, header?: string, message?: string, technicalDetails?: string,
        actions?: IAlertAction[], closeActionTitle?: string, onClose?: () => void,
        isOpen: boolean, isBlocking: boolean, children?: JSX.Element | JSX.Element[]
    }) {
        super(props);

        this.state = {
            title: props.title,
            header: props.header,
            message: props.message,
            technicalDetails: props.technicalDetails,
            isOpen: props.isOpen,
            isBlocking: props.isBlocking,
            actions: props.actions,
            closeActionTitle: props.closeActionTitle ? props.closeActionTitle : "Close"
        };
    }

    public render() {
        const {
            title, header, message, technicalDetails, isOpen, isBlocking, actions, closeActionTitle } = this.state;

        return (
            <Dialog
                hidden={!isOpen}
                onDismiss={this._closeDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: title,
                }}
                modalProps={{
                    isBlocking: isBlocking
                }}
                styles={{
                    main: {
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                width: 640,
                                minWidth: 300,
                                maxWidth: '1000px'
                            }
                        }
                    }
                }}
            >

                {header ? (
                    <Text variant='xLarge'>
                        <br/>{header}<br /><br/>
                    </Text>) : ""}

                {message ? (
                    <Text variant='medium'>
                        <div dangerouslySetInnerHTML={{ __html: message! }} /><br /><br />
                    </Text>) : (
                    <Text variant='medium'>
                        No further information provided.<br /><br />
                    </Text>
                )}

                {technicalDetails ? (
                    <div>
                        <Text variant='medium'>
                            <b>Technical details:</b><br />
                        </Text>
                        <Text variant='medium'>
                            <b>{technicalDetails}</b><br />
                        </Text>
                    </div>) : ""}

                {this.props.children ? this.props.children : ""}

                <DialogFooter>
                    <>
                    {actions ? actions.map((a: IAlertAction) => {
                        return a.primary ? (
                            <PrimaryButton text={a.title} onClick={a.action} styles={buttonStyles}/>
                        ) : (
                                <DefaultButton text={a.title} onClick={a.action} styles={buttonStyles} />
                        )
                    }) : ""
                        }

                        {actions && actions.find((a: IAlertAction) => a.primary) ?
                            (<DefaultButton onClick={this._closeDialog} text={closeActionTitle} styles={buttonStyles} />) :
                            (<PrimaryButton onClick={this._closeDialog} text={closeActionTitle} styles={buttonStyles} />)}
                    </>
                </DialogFooter>
            </Dialog>

        );
    }

    componentDidMount() {
        this.setState({
            title: this.props.title,
            header: this.props.header,
            message: this.props.message,
            technicalDetails: this.props.technicalDetails,
            isOpen: this.props.isOpen,
            isBlocking: this.props.isBlocking,
            actions: this.props.actions,
            closeActionTitle: this.props.closeActionTitle ? this.props.closeActionTitle : "Close"
        });
    }

    componentDidUpdate(previousProps: any, previousState: IAlertState) {
        if (previousProps.title !== this.props.title ||
            previousProps.header !== this.props.header ||
            previousProps.message !== this.props.message ||
            previousProps.technicalDetails !== this.props.technicalDetails ||
            previousProps.isOpen !== this.props.isOpen ||
            previousProps.isBlocking !== this.props.isBlocking)
                this.setState({
                    title: this.props.title,
                    header: this.props.header,
                    message: this.props.message,
                    technicalDetails: this.props.technicalDetails,
                    isOpen: this.props.isOpen,
                    isBlocking: this.props.isBlocking
                });
    }

    private _closeDialog = (): void => {
        if (this.props.onClose)
            this.props.onClose();
    }
}