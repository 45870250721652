import * as React from 'react';
import {
    SelectionMode, ICommandBarProps, Spinner, SpinnerSize,
    Panel, PanelType, Link, ILinkStyleProps, ILinkStyles,
    IconButton, DetailsList, IColumn, IGroup, GroupHeader, IDetailsGroupDividerProps, FontIcon, mergeStyles, mergeStyleSets,
    CommandBar
} from '@fluentui/react';
import { ReleaseEditor } from './ReleaseEditor';
import { ReleaseNoteEditor } from './ReleaseNoteEditor';
import { AlertMessage } from './AlertMessage';
import { AccessDenied } from './AccessDenied';
import { _copyAndSort } from './util/util';
import { acquireAccessToken } from '../auth/AccessTokenHelper';

export interface IReleaseNote {
    key: number;
    id: number;
    version: string;
    releaseNoteId: string;
    title: string;
    tags: string[];
    state: string;
    majorChange: boolean;
    description: string;
    actionRequiredBy: Date;
    lastModified: Date;
    lastModifiedBy: string;
}

export interface IRelease {
    key: number;
    id: number;
    version: string;
    title: string;
    notes: IReleaseNote[];
    releaseTimestamp: Date;
    lastModified: Date;
    lastModifiedBy: string;
}

interface IReleaseHeaderItem {
    id: number;
    name: string;
    version: string;
    releaseDate: Date;
}

interface IReleaseNotesState {
    releases: IRelease[];
    releaseNotes: IReleaseNote[];
    selectedReleaseNote: number;
    selectedRelease: number;
    groups: IGroup[];
    releaseNoteEditorOpen: boolean;
    releaseEditorOpen: boolean;
    releaseNoteToDelete: number;
    initialized: boolean;
    accessGranted: boolean;
    error?: string;
}

export class ReleaseNotes extends React.Component<{}, IReleaseNotesState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            releases: [],
            releaseNotes: [],
            groups: [],
            selectedReleaseNote: -1,
            selectedRelease: -1,
            releaseNoteEditorOpen: false,
            releaseEditorOpen: false,
            releaseNoteToDelete: -1,
            initialized: false,
            accessGranted: false,
            error: undefined
        };
    }

    public render() {
        const {
            releases, releaseNotes, selectedReleaseNote, selectedRelease,
            releaseNoteEditorOpen, releaseEditorOpen, releaseNoteToDelete,
            groups, initialized, accessGranted, error
        } = this.state;

        if (!initialized)
            return (<div className="container" style={{ height: "calc(100vh - 72px)" }}>
                <div className="row" style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    <div className="col">
                        <Spinner label="Loading data..." size={SpinnerSize.large} />
                    </div>
                </div>
            </div>);

        if (!accessGranted)
            return (
                <AccessDenied />
            );

        const releaseNoteToDeleteObj: IReleaseNote | undefined =
            releaseNoteToDelete > 0 ? releaseNotes.find((r: IReleaseNote) => r.id === releaseNoteToDelete) : undefined;

        const pipeFabricStyles = (p: ILinkStyleProps): ILinkStyles => ({
            root: {
                textDecoration: 'none',
                color: p.theme.semanticColors.bodyText
            },
        });

        const iconClass = mergeStyles({
            fontSize: 16,
            height: 16,
            width: 16,
            margin: "0 16px"
        });

        const classNames = mergeStyleSets({
            error: [{ color: '#dc3545' }, iconClass],
            ok: [{ color: '#107c10' }, iconClass],
            information: [{ color: 'dodgerblue' }, iconClass],
            warning: [{ color: '#ffb900' }, iconClass],
            sync: [{ color: 'dodgerblue' }, iconClass]
        });

        const columns: IColumn[] = [
            {
                key: 'releaseNoteId',
                name: 'Id',
                minWidth: 96,
                maxWidth: 96,
                fieldName: 'releaseNoteId',
                isResizable: true,
                isRowHeader: true
            },
            {
                key: 'title',
                name: 'Title',
                minWidth: 150,
                maxWidth: 500,
                fieldName: 'title',
                isResizable: true,
                isRowHeader: true,
                onRender: (item: IReleaseNote) => {
                    return (<Link onClick={(event) => {
                        event.preventDefault();
                        this._onOpenReleaseNoteEditor(item.id);
                    }} styles={pipeFabricStyles}>{item.title}</Link >);
                },
            },
            {
                key: 'noteActions',
                name: '',
                minWidth: 32,
                maxWidth: 32,
                isResizable: false,
                isRowHeader: true,
                onRender: (item: IReleaseNote) => {
                    return <>
                        <IconButton iconProps={{ iconName: 'MoreVertical' }} menuProps={{
                            items: [
                                {
                                    key: 'edit',
                                    text: 'Edit',
                                    iconProps: { iconName: 'Edit' },
                                    onClick: () => this._onOpenReleaseNoteEditor(item.id)
                                },
                                {
                                    key: 'delete',
                                    text: 'Delete',
                                    iconProps: { iconName: 'Delete' },
                                    onClick: () => this._onOpenDeleteReleaseNoteAlert(item.id)
                                },
                            ],
                            directionalHintFixed: true,
                        }} menuIconProps={{ hidden: true }} />
                    </>;
                }
            },
            {
                key: 'majorChange',
                name: 'Major',
                minWidth: 64,
                maxWidth: 64,
                isResizable: false,
                isRowHeader: true,
                onRender: (item: IReleaseNote) => {
                    return <>{item.majorChange ?
                        (<FontIcon aria-label={'Yes'} iconName='WarningSolid' className={classNames.warning} style={{ cursor: 'default' }} />) :
                        ""}</>;
                }
            },
            {
                key: 'actionRequiredBy',
                name: 'Act by',
                minWidth: 64,
                maxWidth: 64,
                isResizable: true,
                isRowHeader: true,
                onRender: (item: IReleaseNote) => {
                    return <div>
                        {new Date(item.actionRequiredBy).toLocaleDateString()}
                    </div>;
                }
            },
            {
                key: 'lastUpdate',
                name: 'Modified',
                minWidth: 64,
                maxWidth: 64,
                isResizable: true,
                isRowHeader: true,
                onRender: (item: IReleaseNote) => {
                    return <div>
                        {new Date(item.lastModified).toLocaleDateString()}
                    </div>;
                }
            },
            {
                key: 'lastUpdateBy',
                name: 'Modified by',
                minWidth: 64,
                maxWidth: 256,
                isResizable: true,
                isRowHeader: true,
                onRender: (item: IReleaseNote) => {
                    return <div>
                        {item.lastModifiedBy}
                    </div>;
                }
            }
        ];

        const commandBarProps: ICommandBarProps = {
            items: [
                {
                    key: 'new',
                    text: 'New',
                    iconProps: { iconName: 'Add' },
                    subMenuProps: {
                        items: [
                            {
                                key: 'newRelease',
                                text: 'New release',
                                iconProps: { iconName: 'AppIconDefaultAdd' },
                                onClick: () => this._onOpenReleaseEditor(-1)
                            },
                            {
                                key: 'newReleaseNote',
                                text: 'New release note',
                                iconProps: { iconName: 'AddNotes' },
                                onClick: () => this._onOpenReleaseNoteEditor(-1)
                            }
                        ]
                    }
                }
            ]
        };

        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <CommandBar
                            items={commandBarProps.items}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <DetailsList
                            columns={columns}
                            items={releaseNotes}
                            groups={groups}
                            groupProps={{
                                showEmptyGroups: true,
                                onRenderHeader: (props: IDetailsGroupDividerProps | undefined) => this.onRenderHeader(props)
                            }}
                            selectionMode={SelectionMode.none}
                        />
                        <Panel
                            isOpen={releaseNoteEditorOpen}
                            isBlocking={true}
                            isLightDismiss={false}
                            type={PanelType.large}
                            headerText='Edit release note'
                            onDismiss={() => this._onCloseReleaseNoteEditor()}
                        >
                            <ReleaseNoteEditor
                                selectedIndex={selectedReleaseNote}
                                releaseNotes={releaseNotes}
                                releases={releases}
                                onSave={(data: IReleaseNote) => this._onSaveReleaseNote(data)}
                                onCancel={() => this._onCloseReleaseNoteEditor()}
                            />
                        </Panel>

                        <Panel
                            isOpen={releaseEditorOpen}
                            isBlocking={true}
                            isLightDismiss={false}
                            type={PanelType.medium}
                            headerText='Edit release'
                            onDismiss={() => this._onCloseReleaseEditor()}
                        >
                            <ReleaseEditor
                                selectedIndex={selectedRelease}
                                releases={releases}
                                onSave={(data: IRelease) => this._onSaveRelease(data)}
                                onCancel={() => this._onCloseReleaseEditor()}
                                onDelete={(data: IRelease) => this._onDeleteRelease(data)}
                            />
                        </Panel>

                        {releaseNoteToDeleteObj ? (
                            <AlertMessage
                                isBlocking={true}
                                title='Remove release note'
                                header={'Removing release note ' + releaseNoteToDeleteObj.releaseNoteId + '.'}
                                message='You cannot undo this operation. Are you sure?'
                                isOpen={releaseNoteToDelete > 0}
                                actions={
                                    [
                                        {
                                            title: 'Yes',
                                            action: () => this.deleteReleaseNote(releaseNoteToDeleteObj)
                                        }
                                    ]
                                }
                                closeActionTitle='No'
                                onClose={() => this.closeAlert()}
                            />
                        ) : ""}
                    </div>
                </div>
            </div>);
    }

    componentDidMount() {
        this._onLoadConfig();
    }

    private _onLoadConfig(): void {
        const requiredRoles: string[] = ['Publisher'];
        var releases: IRelease[] = [];
        var releaseNotes: IReleaseNote[] = [];
        var releaseGroups: IGroup[] = [];

        this.setState({
            initialized: false
        });

        acquireAccessToken()
            .then((response) => {
                var tokenClaims: any = response.account?.idTokenClaims;
                const userRoles: any = tokenClaims?.roles;
                var userHasRequiredRole: boolean = userRoles.some((r: string) => requiredRoles.includes(r));

                if (userHasRequiredRole)
                    fetch('/api/release', { headers: response.idToken === "" ? {} : { 'Authorization': `Bearer ${response.idToken}` } })
                        .then(response => {
                            if (response.ok) {
                                return response.json();
                            } else {
                                this.setState({
                                    error: response.status + " " + response.statusText
                                });
                                throw Error(response.status + " " + response.statusText);
                            }
                        })
                        .then(result => {
                            for (const release of result) {
                                releases.push(release);
                                for (const note of release.notes)
                                    releaseNotes.push(note);
                            }

                            releaseNotes = _copyAndSort(releaseNotes, 'lastModified', false);
                            releaseNotes = _copyAndSort(releaseNotes, 'version', true);
                            releases = _copyAndSort(releases, 'version', true);

                            releaseGroups = this._getGroups(releaseNotes, releases);

                            this.setState({
                                releases: releases,
                                releaseNotes: releaseNotes,
                                groups: releaseGroups,
                                accessGranted: true,
                                initialized: true
                            });

                        }).catch((err) => {
                            this.setState({
                                error: err.message
                            });
                        })
                else
                    this.setState({
                        accessGranted: false,
                        initialized: true
                    });
            }).catch(error => {
                this.setState({
                    accessGranted: false,
                    initialized: true
                });
            });

    }

    private _doNothing = (): void => {

    }

    onRenderHeader(props: IDetailsGroupDividerProps | undefined) {
        return (
            <GroupHeader
                {...props}
                
                onGroupHeaderClick={(event) => {
                    this._onOpenReleaseEditor(props?.group ? props?.group?.data.id : -1)
                    }
                } />
        );
    }

    private _getGroups(groupItems: IReleaseNote[], releases: IRelease[]): IGroup[] {
        const serviceGroups: IGroup[] = [];
        let groupsStartIndex = 0;

        releases.forEach((release: IRelease) => {
            const releaseNotes = groupItems.filter((releaseNote: IReleaseNote) => {
                return releaseNote.version === release.version;
            });

            const setGroupCount = releaseNotes.length;
            const headerItem: IReleaseHeaderItem = {
                id: release.id,
                name: release.title,
                version: release.version,
                releaseDate: release.releaseTimestamp,              
            };

            const group: IGroup = {
                key: release.version,
                name: release.title + ' (' + release.version + ')',
                startIndex: groupsStartIndex,
                count: setGroupCount,
                data: headerItem,
            };

            groupsStartIndex += setGroupCount;

            serviceGroups.push(group);
        });

        return serviceGroups;
    };

    private _onOpenReleaseNoteEditor = (index: number) => {
        this.setState({
            selectedReleaseNote: index,
            releaseNoteEditorOpen: true
        });
    }

    private _onCloseReleaseNoteEditor = () => {
        this.setState({
            selectedReleaseNote: -1,
            releaseNoteEditorOpen: false
        });
    }

    private _onSaveRelease(data: IRelease): void {
        var params: RequestInit;

        acquireAccessToken()
            .then((response) => {
                params = {
                    headers: {
                        "Content-Type": "application/json charset=UTF-8",
                        "Authorization": "Bearer " + response.idToken
                    },
                    body: JSON.stringify(data),
                    method: "POST"
                };

                const uri: string = '/api/release';

                fetch(uri, params)
                    .then((response) => {

                        this._onCloseReleaseEditor();

                        if (!response.ok) {
                            // make the promise be rejected if we didn't get a 2xx response
                            const err = new Error("Couldn't save release. Error details:<br/><br/><b>HTTP " + response.status + "</b><br/>" + response.statusText);
                            throw err;
                        }

                        return response.json();
                    })
                    .then(response => {
                        var r: IRelease[] | undefined = this.state.releases;

                        if (data.id > -1) {
                            r = r.filter(r => r.version !== response.version);
                        }

                        r.push(response);

                        var rn = _copyAndSort(this.state.releaseNotes, 'lastModified', false);
                        rn = _copyAndSort(rn, 'version', true);
                        r = _copyAndSort(r, 'version', true);

                        var releaseGroups = this._getGroups(rn, r);

                        this.setState({
                            releases: r,
                            releaseNotes: rn,
                            groups: releaseGroups,
                            initialized: true
                        });
                    });
            });
    }

    private _onDeleteRelease(data: IRelease): void {
        var params: RequestInit;

        acquireAccessToken()
            .then((response) => {
                params = {
                    headers: {
                        "Content-Type": "application/json charset=UTF-8",
                        "Authorization": "Bearer " + response.idToken
                    },
                    method: "DELETE"
                };

                const uri: string = '/api/release/' + data.version;

                fetch(uri, params)
                    .then((response) => {

                        this._onCloseReleaseEditor();

                        if (!response.ok) {
                            // make the promise be rejected if we didn't get a 2xx response
                            const err = new Error("Couldn't delete release. Error details:<br/><br/><b>HTTP " + response.status + "</b><br/>" + response.statusText);
                            throw err;
                        }
                    })
                    .then(() => {
                        this._onLoadConfig();
                    });
            });
    }

    private deleteReleaseNote(data: IReleaseNote): void {
        var params: RequestInit;

        acquireAccessToken()
            .then((response) => {
                params = {
                    headers: {
                        "Content-Type": "application/json charset=UTF-8",
                        "Authorization": "Bearer " + response.idToken
                    },
                    method: "DELETE"
                };

                const uri: string = '/api/releasenote/' + data.releaseNoteId;

                fetch(uri, params)
                    .then((response) => {
                        this.closeAlert();

                        if (!response.ok) {
                            // make the promise be rejected if we didn't get a 2xx response
                            const err = new Error("Couldn't delete release note. Error details:<br/><br/><b>HTTP " + response.status + "</b><br/>" + response.statusText);
                            throw err;
                        }
                    })
                    .then(() => {
                        this._onLoadConfig();
                    });
            });
    }

    _onOpenDeleteReleaseNoteAlert(id: number): void {
        this.setState({
            releaseNoteToDelete: id
        });
    }

    closeAlert(): void {
        this.setState({
            releaseNoteToDelete: -1
        });
    }

    private _onOpenReleaseEditor = (index: number) => {
        this.setState({
            selectedRelease: index,
            releaseEditorOpen: true
        });
    }

    private _onCloseReleaseEditor = () => {
        this.setState({
            selectedRelease: -1,
            releaseEditorOpen: false
        });
    }

    private _onSaveReleaseNote(data: IReleaseNote): void {
        var params: RequestInit;

        acquireAccessToken()
            .then((response) => {
                params = {
                    headers: {
                        "Content-Type": "application/json charset=UTF-8",
                        "Authorization": "Bearer " + response.idToken
                    },
                    body: JSON.stringify(data),
                    method: "POST"
                };

                const uri: string = '/api/releasenote';

                fetch(uri, params)
                    .then((response) => {

                        this._onCloseReleaseNoteEditor();

                        if (!response.ok) {
                            // make the promise be rejected if we didn't get a 2xx response
                            const err = new Error("Couldn't save release note. Error details:<br/><br/><b>HTTP " + response.status + "</b><br/>" + response.statusText);
                            throw err;
                        }

                        return response.json();
                    })
                    .then(response => {
                        var rn: IReleaseNote[] | undefined = this.state.releaseNotes;

                        if (data.id > -1) {
                            rn = rn.filter(r => r.releaseNoteId !== response.releaseNoteId);
                        }

                        rn.push(response);

                        rn = _copyAndSort(rn, 'lastModified', false);
                        rn = _copyAndSort(rn, 'version', true);
                        var releases = _copyAndSort(this.state.releases, 'version', true);

                        var releaseGroups = this._getGroups(rn, releases);

                        this.setState({
                            releases: releases,
                            releaseNotes: rn,
                            groups: releaseGroups,
                            initialized: true
                        });
                    });
            });
    }
}