import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType, SsoSilentRequest } from '@azure/msal-browser';
import { loginRequest } from './auth/authConfig';
import { AccessDenied } from './components/AccessDenied';
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { ReleaseNotes } from "./components/ReleaseNotes";
import './custom.css';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            
          <Layout>
                <Routes>
                    <Route key={1} exact path='/' element={
                        <MsalAuthenticationTemplate
                            interactionType={InteractionType.Redirect}
                            errorComponent={ <AccessDenied /> }
                        >
                                <ReleaseNotes />
                        </MsalAuthenticationTemplate>
                       } />
                 </Routes>
                </Layout>
        );
    }
}
