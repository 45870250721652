import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, MsalAuthenticationTemplate } from "@azure/msal-react";
import { fetchMsalConfig, loginRequest } from "./auth/authConfig";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

initializeIcons();

fetchMsalConfig()
    .then((msalConfig) => {
        const msalInstance = new PublicClientApplication(msalConfig);

        root.render(
            <MsalProvider instance={msalInstance}>
                <BrowserRouter basename={baseUrl}>
                    <App />
                </BrowserRouter>
            </MsalProvider>
                );
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
